import { create } from 'zustand'
import {
  ActivitiesStoreState,
  ActivityUserStateType,
} from './activitiesStore.model'

const initialActivityState: ActivityUserStateType = {
  index: 0,
}

export const useActivitiesStore = create<ActivitiesStoreState>((set) => ({
  ...initialActivityState,
  setIndex: (index: number) => set({ index }),
  reset: () => set({ ...initialActivityState }),
}))
