import { BackendHook } from '../types/router'
import { CommonLogger } from '@goatlab/js-utils'
import { useHomePostFeed } from './useHomePostFeed'
import { UseHomePostProps } from './useHomePostFeed.model'

export const hooks = ({
  backendHook,
  logger,
}: {
  backendHook: BackendHook
  logger: CommonLogger
}) => {
  return {
    useHomePostFeed: (props: UseHomePostProps) => {
      return useHomePostFeed({
        ...props,
        backendHook,
        logger,
      })
    },
  }
}
