import { BackendHook } from '../../types/router'
import { CommonLogger } from '@goatlab/js-utils'
import { useUserStoryQuery } from './useUserStoryQuery'

export const stories = ({
  backendHook,
}: {
  backendHook: BackendHook
  logger: CommonLogger
}) => {
  return {
    useUserStoryQuery: () => {
      return useUserStoryQuery({
        backendHook,
      })
    },
  }
}
