import { RouterInputs } from '../../../types/router'

export type GetAccountsThatLikedPost = RouterInputs['posts']['getAccountsThatLikedPost']

export const getAccountsThatLikedPostDefault: GetAccountsThatLikedPost = {
  perPage: 30,
  postId: '',
}

export const getAccountsThatLikedPostInput = ({
  postId,
}: {
  postId?: string
}): GetAccountsThatLikedPost => {
  return {
    ...getAccountsThatLikedPostDefault,
    postId
  }
}
