import { RouterInputs } from '../../../types/router'

export type CommentActivityQueryInput = RouterInputs['posts']['getPostComments']

const postCommentsQueryInput: CommentActivityQueryInput = {
  perPage: 30,
  postId: '',
}

export const getPostCommentsQueryInput = ({
  postId,
}: {
  postId?: string
}): CommentActivityQueryInput => {
  return {
    ...postCommentsQueryInput,
    postId: postId || '',
  }
}
