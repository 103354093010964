import { BackendHook } from '../../../types/router'
import { homeFeedQueryInput } from './homeFeedQueryInput'

export const useHomeFeedQuery = ({
  backendHook,
}: {
  backendHook: BackendHook
}) => {
  return backendHook.posts.homeFeed.useInfiniteQuery(homeFeedQueryInput, {
    enabled: true,
    getNextPageParam: (lastPage) => {
      return lastPage.nextPage
    },
  })
}
