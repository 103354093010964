import { useAccountStore } from '@goatlab/marketplace-states'
import { useToast } from '@goatlab/react-ui'
import { useEffect, useState } from 'react'
import { useAuth } from '@components/auth/store/auth.store'
import { defaultProfileImg } from '@components/ui/images/images'
import { backendRequest } from '@src/api/trpc'

export const useUserAccountAndRoles = (): void => {
  const [isPulling, setIsPulling] = useState(false)
  const { toast } = useToast()
  const { setAccount, refreshProfileToken, user: authUser, logOut } = useAuth()
  const [refresh, setRefresh] = useState<string | undefined>()
  const { selectAccount } = useAccountStore()

  const getMe = async () => {
    setIsPulling(true)
    setRefresh(refreshProfileToken)

    try {
      const myAccount = await backendRequest.user.me.query()
      if (myAccount) {
        setIsPulling(false)
        setAccount(myAccount)

        selectAccount({
          id: myAccount.id,
          isOrganization: false,
          imageUrl: myAccount.profilePicture || defaultProfileImg,
          slug: myAccount.slug,
          name: myAccount.displayName ?? '',
        })
      }
    } catch (err: any) {
      await logOut()
      toast({
        title: err.name,
        description: err.message,
        variant: 'destructive',
      })
      setIsPulling(false)
      return
    }
  }

  useEffect(() => {
    const noProfile = !authUser?.account && authUser?.token
    const shouldRefresh = refreshProfileToken !== refresh

    if ((noProfile || shouldRefresh) && !isPulling) {
      void getMe()
    }
  }, [authUser?.token, refreshProfileToken])
}
