import { EnvironmentDev } from './dev.env'

export class EnvironmentLocal extends EnvironmentDev {
  name = 'local'
  prod = false
  dev = false
  local = true
  test = false

  BASE_HOST_URL = process.env.NEXT_PUBLIC_BASE_HOST_URL || 'http://127.0.0.1'
  BACKEND_URL = `https://api.a.getsodium.com`
  LINKEDIN_CLIENT_ID = '78wy10ljao5i5a'
  LINKEDIN_REDIRECT_URL = `${this.BACKEND_URL}/linkedin/auth`
  FIREBASE_EMULATOR_URL = `https://firebase.a.getsodium.com`
  TYPESENSE_URL = `https://typesense.a.getsodium.com`
  TYPESENSE_TOKEN = 'MY_API_KEY'
}

export const env = new EnvironmentLocal()
