import { create } from 'zustand'

export interface MarketplaceAsset {
  url: string
  originalName: string
  mimeType: string
  isImage: boolean
  isVideo: boolean
  isFile: boolean
  height?: number
  width?: number
  isVertical?: boolean
  sizeBytes?: number
  thumbnailUrl?: string
  duration?: number
  pages?: number
  storyId?: string
}

export interface NewPostState {
  renderKey: string
  index: number
  postId?: string
  content: string
  assets: MarketplaceAsset[]
  isReOrderingAssets: boolean
  isPreviewing?: boolean
  isLoadingMedia?: boolean
  isPosting?: boolean
  hasAssets?: boolean
  hasSeveralAssets?: boolean
  postHasContent?: boolean
  isReadyToPost?: boolean
}

export interface NewPostAction {
  setContent: (content?: string) => void
  addAsset: (asset: MarketplaceAsset) => void
  replaceAsset: (asset: MarketplaceAsset, newAsset: MarketplaceAsset) => void
  setAssets: (asset: MarketplaceAsset[]) => void
  setIsReorderingAssets: (isReOrderingAssets: boolean) => void
  toggleReorderingAssets: () => void
  resetKey: () => void
  reset: () => void
  setIsLoadingMedia: (isLoadingMedia: boolean) => void
  setIsPosting: (isPosting: boolean) => void
  setIsPreviewing: (isPreviewing: boolean) => void
  setIndex: (index: number) => void
  setPostId: (postId: string) => void
}

const defaultState: NewPostState = {
  renderKey: '1',
  index: 0,
  postId: undefined,
  isPreviewing: false,
  hasAssets: false,
  postHasContent: false,
  hasSeveralAssets: false,
  isReadyToPost: false,
  content: '',
  assets: [],
  isReOrderingAssets: false,
  isLoadingMedia: false,
  isPosting: false,
}

export const useNewPostStore = create<NewPostState & NewPostAction>((set) => ({
  ...defaultState,
  setIndex: (index) => {
    if (index === 0) {
      set({ index, isPreviewing: false })
      return
    }
    set({ index, isPreviewing: true })
  },
  setPostId: (postId) => {
    set({ postId })
  },
  setContent: (content?: string) => {
    const postHasContent =
      !!content && content.length > 0 && content !== '<p></p>'

    set({ content: content || '', postHasContent })
  },
  setIsPosting: (isPosting) => {
    set({ isPosting })
  },
  setIsPreviewing: (isPreviewing) => {
    set({ isPreviewing })
  },
  toggleReorderingAssets: () => {
    set((prevState) => {
      return {
        isReOrderingAssets: !prevState.isReOrderingAssets,
      }
    })
  },
  addAsset: (newAsset) => {
    set((prevState) => {
      const assets = [...prevState.assets, newAsset]
      const hasAssets = assets && assets.length > 0
      const hasSeveralAssets = hasAssets && assets.length > 1

      return {
        assets,
        hasAssets,
        hasSeveralAssets,
      }
    })
  },
  setAssets: (assets) => {
    const hasAssets = assets && assets.length > 0
    const hasSeveralAssets = hasAssets && assets.length > 1
    set({ assets, hasAssets, hasSeveralAssets })
  },
  setIsLoadingMedia: (isLoadingMedia) => {
    set({ isLoadingMedia })
  },
  setIsReorderingAssets: (isReOrderingAssets) => {
    set({ isReOrderingAssets })
  },
  replaceAsset: (asset, newAsset) =>
    set((state) => {
      const assets = state.assets.map((a) =>
        a.url === asset.url
          ? {
              ...{
                ...a,
                ...newAsset,
              },
              url: newAsset.url,
            }
          : a
      )
      return { assets }
    }),
  resetKey: () => {
    set({ renderKey: Math.random().toString(36).substring(2, 8) })
  },
  reset: () => {
    set({
      ...defaultState,
      renderKey: Math.random().toString(36).substring(2, 8),
    })
  },
}))
