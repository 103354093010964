import { create } from 'zustand'
import {
  CommentActivityStateType,
  CommentActivitiesStoreState,
} from './commentsStore.model'
import { ActivityItem } from '../types/types'

const initialCommentActivityState: CommentActivityStateType = {
  activity: undefined,
  activityContent: '',
  isEditing: false,
}

export const useCommentActivitiesStore = create<CommentActivitiesStoreState>(
  (set, get) => ({
    ...initialCommentActivityState,
    setIsEditing: (isEditing) => set({ isEditing }),
    setCommentActivity: (activity: ActivityItem) => set({ activity }),
    setCommentActivityContent: (content: string) => {
      set({ activityContent: content, ...get().activity })
    },
    resetCommentActivity: () =>
      set({ activity: undefined, activityContent: '', isEditing: false }),
    reset: () => set({ ...initialCommentActivityState }),
  })
)
