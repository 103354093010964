import { CommonLogger } from '@goatlab/js-utils'
import { BackendHook, RouterOutputs } from '../../../types/router'
import { getAccountFeedQueryInput } from '../accountFeed/accountFeedQueryInput'
import { homeFeedQueryInput } from '../homeFeed/homeFeedQueryInput'
import type { Post } from '../../../types/Post'

export type ActivityItem = RouterOutputs['posts']['getPostComments']['data'][0]

export const useDeletePost = ({
  backendHook,
  visibleAccountId,
  onSuccess,
  beforeMutate,
  afterMutate,
  logger,
}: {
  visibleAccountId?: string
  backendHook: BackendHook
  logger: CommonLogger
  beforeMutate?: (props: { postId: string }) => void
  afterMutate?: (props: { postId: string }) => void
  onSuccess?: (props: { postId: string }) => void
}) => {
  const utils = backendHook.useUtils()
  const accountQueryOptions = getAccountFeedQueryInput({
    accountId: visibleAccountId,
  })
  const homeQueryOptions = homeFeedQueryInput

  return backendHook.posts.deleteById.useMutation({
    onMutate: async (deletedPost) => {
      //await postOptionsModalRef.current?.dismiss()
      //
      beforeMutate?.(deletedPost)

      // await postOptionsModalRef.current?.dismiss()
      let deletedPostInfo:
        | { post: Post; pageIndex: number; postIndex: number }
        | undefined = undefined

      const previousAccountData =
        utils.posts.accountFeed.getInfiniteData(accountQueryOptions)

      utils.posts.homeFeed.setInfiniteData(homeQueryOptions, (oldHomeFeed) => {
        if (!oldHomeFeed) return { pageParams: [], pages: [] }

        return {
          ...oldHomeFeed,
          pages: oldHomeFeed.pages.map((page, pageIndex) => ({
            ...page,
            data: page.data.filter((post, postIndex) => {
              const isTargetPost = post.id === deletedPost.postId
              if (isTargetPost) {
                deletedPostInfo = { post, pageIndex, postIndex }
              }
              return !isTargetPost
            }),
          })),
        }
      })

      if (previousAccountData)
        utils.posts.accountFeed.setInfiniteData(
          accountQueryOptions,
          (oldAccountFeed) => {
            if (!oldAccountFeed) return { pageParams: [], pages: [] }

            return {
              ...oldAccountFeed,
              pages: oldAccountFeed.pages.map((page, pageIndex) => ({
                ...page,
                data: page.data.filter((post, postIndex) => {
                  const isTargetPost = post.id === deletedPost.postId
                  if (isTargetPost) {
                    deletedPostInfo = { post, pageIndex, postIndex }
                  }
                  return !isTargetPost
                }),
              })),
            }
          }
        )
      afterMutate?.(deletedPost)
      return { deletedPostInfo, previousAccountData }
    },
    onSuccess: (deletedPost) => {
      // return notifySuccess('Post deleted')
      onSuccess?.({ postId: deletedPost.id })
    },
    onError: (err, deletedPost, context) => {
      logger.error(err)
      if (context?.deletedPostInfo) {
        // Rollback the specific deleted post
        utils.posts.homeFeed.setInfiniteData(
          homeQueryOptions,
          (oldHomeFeed) => {
            if (!oldHomeFeed) return { pageParams: [], pages: [] }

            const { post, pageIndex, postIndex } = context.deletedPostInfo
            return {
              ...oldHomeFeed,
              pages: oldHomeFeed.pages.map((page, idx) => {
                if (idx === pageIndex) {
                  return {
                    ...page,
                    data: [
                      ...page.data.slice(0, postIndex),
                      post,
                      ...page.data.slice(postIndex),
                    ],
                  }
                }
                return page
              }),
            }
          }
        )
        if (context?.previousAccountData)
          // Rollback the specific deleted post in the account feed
          utils.posts.accountFeed.setInfiniteData(
            accountQueryOptions,
            (oldAccountFeed) => {
              if (!oldAccountFeed) return { pageParams: [], pages: [] }

              const { post, pageIndex, postIndex } = context.deletedPostInfo
              return {
                ...oldAccountFeed,
                pages: oldAccountFeed.pages.map((page, idx) => {
                  if (idx === pageIndex) {
                    return {
                      ...page,
                      data: [
                        ...page.data.slice(0, postIndex),
                        post,
                        ...page.data.slice(postIndex),
                      ],
                    }
                  }
                  return page
                }),
              }
            }
          )
        notifyError('Error deleting post')
      }
    },
  })
}
