import { BackendHook } from '../../../types/router'
import { getPostCommentsQueryInput } from './getPostCommentsQueryInput'

export const useGetPostComments = ({
  postId,
  backendHook,
}: {
  postId?: string
  backendHook: BackendHook
}) => {
  const enabled: boolean = !!postId

  return backendHook.posts.getPostComments.useInfiniteQuery(
    getPostCommentsQueryInput({
      postId,
    }),
    {
      enabled,
      getNextPageParam: (lastPage) => {
        return lastPage.nextPage
      },
    }
  )
}
