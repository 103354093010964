export class EnvironmentProd {
  name = 'prod'
  prod = true
  dev = false
  local = false
  test = false

  APP_NAME = 'agrosocial'
  BASE_DOMAIN = 'agrosocial.com'

  PUSHER_APP_KEY = '5c078987fa10c0946698'
  PUSHER_APP_CLUSTER = 'mt1'

  SENTRY_DNS =
    'https://7c39ea6bee916daaac14482a31fc9bb0@o4505985012793344.ingest.sentry.io/4506097514250240'

  BASE_HOST_URL = `https://api.${this.BASE_DOMAIN}`
  BACKEND_URL = this.BASE_HOST_URL
  LINKEDIN_CLIENT_ID = '78wy10ljao5i5a'
  LINKEDIN_REDIRECT_URL = `${this.BACKEND_URL}/linkedin/auth`
  FIREBASE_EMULATOR_URL = ''
  TYPESENSE_URL = `https://i6epxjy5awzso07dp-1.a1.typesense.net`
  TYPESENSE_TOKEN = '6ma4WttUkenWjVGZ2SeNwaqdPwf0Yqyu'

  IOS_APP_ID = 'com.agrosocial.prod'
  ANDROID_APP_ID = 'com.agrosocial.production'
}

export type Environment = EnvironmentProd

export const env = new EnvironmentProd()
