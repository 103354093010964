import { BackendHook } from '../../../types/router'
import { getAccountFeedQueryInput } from './accountFeedQueryInput'

export const useAccountFeedQuery = ({
  visibleAccountId,
  backendHook,
}: {
  backendHook: BackendHook
  visibleAccountId?: string
}) => {
  const input = getAccountFeedQueryInput({ accountId: visibleAccountId })
  const enabled = !!visibleAccountId

  return backendHook.posts.accountFeed.useInfiniteQuery(input, {
    enabled,
    getNextPageParam: (lastPage) => lastPage?.nextPage,
  })
}
