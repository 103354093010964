import { useEffect } from 'react'
import { sentryService } from '@src/services/sentry/sentry.service'
import { pkg } from '@src/consts'

export const useSentry = () => {
  useEffect(() => {
    require('requestidlecallback-polyfill')

    globalThis.requestIdleCallback(async () => {
      console.log(`GEALIUM version ${pkg.version}`)
      await sentryService.init({ server: false })
    })
  }, [])
}
