import { BackendHook, RouterOutputs } from '../../types/router'

export type UserStory = RouterOutputs['stories']['getStories']['data'][number]
export type Story = UserStory['stories'][number]
export type UserStoriesMetaData = Story['metaData']

export const useUserStoryQuery = ({
  backendHook,
}: {
  backendHook: BackendHook
}) => {
  return backendHook.stories.getStories.useInfiniteQuery(
    {
      perPage: 50,
    },
    {
      getNextPageParam: (page) => page.nextPage,
    }
  )
}
