import { BackendHook } from '../../../types/router'
import { getAccountsThatLikedPostInput } from './getAccountThatLikedPostInput'

export const useGetAccountsThatLikedPost = ({
  postId,
  backendHook,
}: {
  backendHook: BackendHook
  postId?: string
}) => {
  const input = getAccountsThatLikedPostInput({ postId })
  const enabled = !!postId

  return backendHook.posts.getAccountsThatLikedPost.useInfiniteQuery(input, {
    enabled,
    getNextPageParam: (lastPage) => lastPage?.nextPage,
  })
}
