import { create } from 'zustand'
import type { RouterOutputs } from '../../../../apps/expo/src/api/trpc'

export type HomePostType = RouterOutputs['posts']['homeFeed']['data'][0]

export type HomeFeedStateType = {
  selectedPost?: HomePostType
  visibleAccountId?: string
}

export type HomeFeedActionType = {
  setSelectedPost: (selectedPost: HomePostType) => void
  setVisibleAccountId: (visibleAccountId: string) => void
}

const initialSelectedPost: HomeFeedStateType = {
  selectedPost: undefined,
  visibleAccountId: undefined,
}

export const useHomeFeedStore = create<HomeFeedStateType & HomeFeedActionType>(
  (set, state) => ({
    ...initialSelectedPost,
    setSelectedPost: (selectedPost: HomePostType) => set({ selectedPost }),
    setVisibleAccountId: (visibleAccountId: string) => {
      if (state().visibleAccountId !== visibleAccountId) {
        set({ visibleAccountId })
      }
    },
  })
)
