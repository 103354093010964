import { Account } from '../../../types/Account'
import { BackendHook } from '../../../types/router'
import { Ids } from '@goatlab/js-utils'
import { ActivityItem } from './useDeletePostComment'
import { CommonLogger } from '@goatlab/js-utils'
import { getPostCommentsQueryInput } from './getPostCommentsQueryInput'
import { useHomeFeedStore } from '@goatlab/marketplace-states'
import { PostCache } from '../PostCache'

export const useCommentOnPost = ({
  backendHook,
  visibleAccountId,
  account,
  reset,
  logger,
}: {
  visibleAccountId?: string
  backendHook: BackendHook
  account?: Account
  reset?: () => Promise<void>
  logger: CommonLogger
}) => {
  const utils = backendHook.useUtils()
  const postCache = new PostCache(utils)

  const { selectedPost, setSelectedPost } = useHomeFeedStore()

  // We will use this local Id to replace the item once the
  // mutation has been settled
  const localId = `local_${Ids.uuid()}`
  return backendHook.posts.commentOnPost.useMutation({
    meta: {
      localId,
    },
    onMutate: async (commentParams) => {
      const currentDate = new Date().toISOString()

      // Create the optimistic comment
      const newComment: Partial<ActivityItem> = {
        id: localId,
        content: commentParams.comment,
        accountId: account?.id || '',
        account: {
          id: account?.id || '',
          displayName: account?.displayName || '',
          profilePicture: account?.profilePicture || '',
          title: account?.title || '',
          slug: account?.slug || '',
        },
        postId: commentParams.postId,
        commentsCount: 0,
        reactionsCount: 0,
        viewsCount: 0,
        sharesCount: 0,
        type: 'POST_COMMENT',
        updated: currentDate,
        created: currentDate,
        deleted: null,
      }

      // Optimistically update the individual comment list for the activity
      utils.posts.getPostComments.setInfiniteData(
        getPostCommentsQueryInput({
          postId: commentParams.postId,
        }),
        (previousComments) => {
          if (!previousComments) {
            return { pageParams: [], pages: [] }
          }

          previousComments.pages[0].data.unshift(newComment)
          return previousComments
        }
      )

      await postCache.updateById({
        postId: commentParams.postId,
        visibleAccountId,
        updateCallback: (post) => {
          post.commentsCount = (Number(post.commentsCount) || 0) + 1
        },
      })

      await reset?.()

      return { localId }
    },
    onSuccess: async (backendResponse, input, context) => {
      if (!backendResponse || !backendResponse.postId) {
        return
      }

      utils.posts.getPostComments.setInfiniteData(
        getPostCommentsQueryInput({
          postId: input.postId,
        }),
        (previousComments) => {
          if (!previousComments) {
            return { pageParams: [], pages: [] }
          }

          for (
            let pageIndex = 0;
            pageIndex < previousComments.pages.length;
            pageIndex++
          ) {
            for (
              let commentIndex = 0;
              commentIndex < previousComments.pages[pageIndex].data.length;
              commentIndex++
            ) {
              const comment =
                previousComments.pages[pageIndex].data[commentIndex]

              if (comment && comment.id === context.localId) {
                // Mutate the object in place without creating new objects
                previousComments.pages[pageIndex].data[commentIndex].id =
                  backendResponse.id

                return previousComments // Exit both loops by returning
              }
            }
          }

          return previousComments
        }
      )
    },
    onError: async (error, input, context) => {
      if (!context) {
        return
      }
      logger.error(error)

      await postCache.updateById({
        postId: input.postId,
        visibleAccountId,
        updateCallback: (post) => {
          post.commentsCount = (Number(post.commentsCount) || 0) - 1
        },
      })

      utils.posts.getPostComments.setInfiniteData(
        getPostCommentsQueryInput({
          postId: input.postId,
        }),
        (previousComments) => {
          if (!previousComments) {
            return { pageParams: [], pages: [] }
          }

          // To trigger the refresh we must
          // spread the previous comments
          return {
            ...previousComments,
            // From every page
            pages: previousComments.pages.map((page) => ({
              ...page,
              // Filter the list of comments so we
              // can also remove it there
              data: page.data.filter((comment) => {
                const isCommentToDelete = comment.id === context.localId

                return !isCommentToDelete
              }),
            })),
          }
        }
      )
    },
  })
}
