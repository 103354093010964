import { create } from 'zustand'

export interface ItemAwareFlashListStoreContext {
  focusedItemId?: string
  setFocusedItemId: (focusedItemId: string) => void
  focusedItemIndex?: number
  setFocusedItemIndex: (focusedItemIndex: number) => void
  focusedItem?: any
  setFocusedItem: (focusedItem: any) => void

  viewableItemsIndex: number[]
  setViewableItemsIndex: (viewableItemsIndex: number[]) => void

  viewableItemIds: string[]
  setViewableItemIds: (ids: string[]) => void
  viewableItems: any[]
  setViewableItems: (items: any[]) => void
  isMuted: boolean
  setIsMuted: (isMuted: boolean) => void

  getFocusedItemId: () => string | undefined
}

export const useFlashListStore = create<ItemAwareFlashListStoreContext>(
  (set, state) => ({
    viewableItemsIndex: [],
    setViewableItemsIndex: (viewableItemsIndex) => {
      set({
        viewableItemsIndex,
      })
    },
    focusedItemId: undefined,
    setFocusedItemId: (focusedItemId) => {
      set({
        focusedItemId,
      })
    },
    focusedItemIndex: undefined,
    setFocusedItemIndex: (focusedItemIndex) => {
      set({
        focusedItemIndex,
      })
    },
    focusedItem: undefined,
    setFocusedItem: (focusedItem: any) => {
      set({
        focusedItem,
      })
    },
    viewableItemIds: [],
    viewableItems: [],
    isMuted: false,
    setViewableItemIds: (viewableItemIds) => {
      set({
        viewableItemIds,
      })
    },
    setViewableItems: (viewableItems) => {
      set({
        viewableItems,
      })
    },
    setIsMuted: (isMuted) => {
      set({
        isMuted,
      })
    },
    getFocusedItemId: () => {
      return state().focusedItemId
    },
  })
)
